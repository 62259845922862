import { SliceZone } from "@prismicio/react";
import { components } from "slices";
import type { GetStaticProps, InferGetStaticPropsType, NextPage } from "next";
import { PageLayout } from "~/core/components/Layout/PageLayout";
import { createClient } from "prismicio";
import { type FooterDocument, type HomeDocument } from "prismicio-types";
import { SeoMeta } from "~/coreUI/Content/SeoMeta/SeoMeta";

export const Homepage: NextPage<
  InferGetStaticPropsType<typeof getStaticProps>
> = ({ page, footer }) => {
  //

  return (
    <PageLayout footer={footer} isPublic>
      <SeoMeta {...page} />
      <SliceZone slices={page.data.slices} components={components} context />
    </PageLayout>
  );
};

export default Homepage;

export const getStaticProps: GetStaticProps<{
  page: HomeDocument;
  footer: FooterDocument;
}> = async ({ previewData }) => {
  const client = createClient({
    previewData,
  });
  const footer = await client.getSingle("footer", {
    fetchLinks: ["page.title"],
  });

  const page = await client.getSingle("home");

  return {
    props: { page, footer },
  };
};
